import { Component, OnInit } from '@angular/core';
import { environment } from 'apps/cmd-frontend/src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { CannedReportService } from '../../../containers/reports/services/canned-report/canned-report.service'
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { SigninService } from "../../../containers/auth-routing/signin/signin.service"
import { StorageService } from '../../services/storage/storage.service';
import {UserFieldConstant} from  "../../constants/user-field-constants";
import { Router } from '@angular/router';
import {CommonService, Error_Message} from '../../services/common/common.service'
import { WorkflowItemService } from '../../../containers/workflow/services/workflow-item/workflow-item.service'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CreateTaskComponent } from '../../components/create-task/create-task.component';
import { CreateNoteComponent } from '../../components/create-note/create-note.component';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {
  public roleId: any;
  public typeId: any;
  public userId:any;
  public customerId:any;
  public userPermission:any;
  public toggledStatus: boolean;
  menuItem: any[];
  modalOption: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    size: 'lg'
  };
  constructor(private spinner: NgxSpinnerService,
    private cannedReportService: CannedReportService,
    private signinService: SigninService,
    private notiService: ToasterNotiService,
    private storageService: StorageService,
    private workflowService: WorkflowItemService,
    private modalService: NgbModal,
    private router:Router,
    private commonService:CommonService) {
    }

  ngOnInit() {
    this.roleId = Number(this.storageService.getItem('roleId'))
    this.typeId = Number(this.storageService.getItem('typeId'));
    this.userId = Number(this.storageService.getItem('userId'))
    this.customerId = Number(this.storageService.getItem('customerId'))

    let userPermission = this.storageService.getItem('userPermission')

   if(!this.commonService.userPermission){
      this.getUserPermission();
   }

    if (this.router.url == '/') {
      this.router.navigate([''])
    }

    if (!userPermission) {
      this.router.navigate([''])
     // this.getUserPermission();
    } else {
      this.userPermission = JSON.parse(userPermission)
      this.commonService.setCurrentUserPermission(this.userPermission)
      this.loadasideMenu();
      this.handleroute(this.userPermission);
    }

    this.commonService.$toggleObservable.subscribe(
      data => {
        this.toggledStatus = data;
      }
    )
  }

  handleSubRoutes(subModule) {
    if (subModule == "Provider Roster Report") {
      this.generateReport(3, 0)
    } else if (subModule == "Location Roster Report") {
      //this.generateReport(2, 0)
      this.generateLocationrosterReport(2,0);
    }
    else if (subModule == "TIN Roster Report") {
      //this.generateReport(1, 0)
      this.generateTinrosterReport(1,0);
    }
    else if (subModule == 'Create A Task') {
      // let navigationExtras: NavigationExtras = {
      //   queryParams: {
      //     "create": 'task'
      //   }
      // };
      // this.router.navigate(["/workflow"], navigationExtras)
      this.addTaskClick()
    }
    else if (subModule == 'Create A Note') {
      this.addNoteClick()
    }
  }

  addTaskClick() {
    let params = {
      customerId: this.customerId,
      userId: this.userId,
      is_task: 1
    }
    this.spinner.show()
    this.workflowService.addTask(params).subscribe(
      data => {
        if (data['success']) {
          let dataToForm = {
            type: 'create',
            data: data,
            place: 'workflow'
          }
          this.modalOption.size = 'xl'
          const modalRef = this.modalService.open(CreateTaskComponent, this.modalOption);
          modalRef.componentInstance.popupType = dataToForm;
          modalRef.result.then((result) => {
            // console.log(result);
            if (result == 'success') {
              // this.GetTasksNotes();
            }
          });
        }
        else {
          this.notiService.showError(data['error'], '', 3000)
        }
        this.spinner.hide()
      },
      error => {
        // console.log(error)
        this.spinner.hide();
      }
    )
  }

  addNoteClick() {
    let params = {
      customerId: this.customerId,
      userId: this.userId,
      is_task: 0
    }
    this.spinner.show()
    this.workflowService.addNote(params).subscribe(
      data => {
        if (data['success']) {
          let dataToForm = {
            type: 'create',
            data: data,
            place: 'workflow'
          }
          this.modalOption.size = 'xl'
          const modalRef = this.modalService.open(CreateNoteComponent, this.modalOption);
          modalRef.componentInstance.popupType = dataToForm;
          modalRef.result.then((result) => {
            // console.log(result);
            if (result == 'success') {
              // this.GetTasksNotes();
            }
          });
        }
        else {
          this.notiService.showError(data['error'], '', 3000)
        }
        this.spinner.hide()
      },
      error => {
        // console.log(error)
        this.spinner.hide();
      }
    )
  }

  generateReport(typeId, reportId) {

    let dataParams = {
      customerId: this.customerId,
      userId: this.userId,
      typeId: typeId,
      reportId: reportId
    }

    this.spinner.show();
    this.cannedReportService.generateProviderTinLocationrosterReport(dataParams).subscribe(
      data => {

        if (data['success_response']) {
          this.notiService.showSuccess(data['success_response'], '', 4000)
          this.spinner.hide();
        }
        else {
          // console.log(data['error']);
        }
        this.spinner.hide();
      },
      error => {
        // console.log(error);
        if (error) {
          this.notiService.showError(Error_Message, '', 4000)
        }
        this.spinner.hide();
      }
    )
  }

  generateTinrosterReport(typeId, reportId) {

    let dataParams = {
      customerId: this.customerId,
      userId: this.userId,
      typeId: typeId,
      reportId: reportId
    }

    this.spinner.show();
    this.cannedReportService.generateTinrosterReport(dataParams).subscribe(
      data => {

        if (data['success_response']) {
           this.notiService.showSuccess(data['success_response'], '', 4000)
          // this.spinner.hide();
        }
        else {
          // console.log(data['error']);
        }
        this.spinner.hide();
      },
      error => {
        // console.log(error);
        if (error) {
          this.notiService.showError(Error_Message, '', 4000)
        }
        this.spinner.hide();
      }
    )
  }

  generateLocationrosterReport(typeId, reportId){
    let dataParams = {
      customerId: this.customerId,
      userId: this.userId,
      typeId: typeId,
      reportId: reportId
    }

    this.spinner.show();
    this.cannedReportService.generateLocationrosterReport(dataParams).subscribe(
      data => {

        if (data['success_response']) {
           this.notiService.showSuccess(data['success_response'], '', 4000)
          // this.spinner.hide();
        }
        else {
          // console.log(data['error']);
        }
        this.spinner.hide();
      },
      error => {
        // console.log(error);
        if (error) {
          this.notiService.showError(Error_Message, '', 4000)
        }
        this.spinner.hide();
      }
    )
  }

  downloadExcelDocument(data) {
    var downloadLink = window.document.createElement('a');
    downloadLink.href = `${environment.apiDownloadUrl}${data['filepath']}`
    downloadLink.download = data['filepath'];
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    this.notiService.showSuccess(data.success_response, '', 4000);
    this.unlinkExcelSheet(data['filepath']);
  }

  getUserPermission() {

    this.commonService.getUserPermission().subscribe(
      data => {

        if (data['user_permissions']) {
          data.user_permissions['chk_ids']= data.user_permissions && data.user_permissions.privileges && data.user_permissions.privileges.chk_ids?data.user_permissions.privileges.chk_ids:null;
          data.user_permissions['isShowAll']=data.user_permissions.customer_id==data.user_permissions.user_id ? true:false
          this.userPermission=data.user_permissions
          this.commonService.setCurrentUserPermission(data.user_permissions);
          this.storageService.setItem('userPermission', JSON.stringify(data.user_permissions))
          console.log(this.userPermission.note_task_user_del_access)
          this.loadasideMenu();
          this.handleroute(data.user_permissions);
        }
        else {
          // console.log(data['error']);
        }
      },
      error => {
        // console.log(error);
        if (error) {
          this.notiService.showError(Error_Message, '', 4000)
        }
        this.spinner.hide();
      }
    )
  }


  handleroute(data){


    if(this.router.url !='/'){
     return
    }
   else if(data.type_id==2 &&(data.role_id==1 || data.isShowAll)){
      this.router.navigate(['/workflow'])
    }
    else if(data.type_id==2 && !data.isShowAll && (data.role_id==2 || data.role_id==3 || data.role_id==4 || data.role_id==5 ||  data.role_id==6) )
    {
       if(data.chk_ids && data.chk_ids.split(",").indexOf(UserFieldConstant.workflow)!=-1){
        this.router.navigate(['/workflow'])
      }else if( data.chk_ids &&data.chk_ids.split(",").indexOf(UserFieldConstant.providerPassport)!=-1){
        this.router.navigate(['/provider'])
      }
      else if(data.chk_ids &&data.chk_ids.split(",").indexOf(UserFieldConstant.manageTInLocation)!=-1){
        this.router.navigate(['/tin'])
      }
      else if(data.chk_ids && data.chk_ids.split(",").indexOf(UserFieldConstant.expiration)!=-1){
        this.router.navigate(['/expirations'])
      }
      else if(data.chk_ids && data.chk_ids.split(",").indexOf(UserFieldConstant.reportsMod)!=-1){
        this.router.navigate(['/reports'])
      }
      else if(data.chk_ids &&data.chk_ids.split(",").indexOf(UserFieldConstant.settingMod)!=-1){
        this.router.navigate(['/settings'])
      }
      else if(data.chk_ids && data.chk_ids.split(",").indexOf(UserFieldConstant.support)!=-1){
        this.router.navigate(['/support'])
      }
      else if(data.chk_ids && data.chk_ids.split(",").indexOf(UserFieldConstant.cmd)!=-1){
        this.router.navigate(['/workflow'])
      }

    }else if(data.type_id == 3|| data.type_id==4||data.type_id==5){
      this.router.navigate(['/provider-portal'])
    }
  }


  unlinkExcelSheet(path){
    let dataParams = {
      customerId: this.customerId,
      userId: this.userId,
      localPath:path,

    }
    this.commonService.unlinkExcelSheet(dataParams).subscribe(
      data => {
        if (data['success']) {
          // console.log(data);
        }
        else {
          // console.log(data['error']);
        }
      },
      error => {
        // console.log(error);
      }
    )
  }



  loadasideMenu() {
this.menuItem = [
  {
    "module_name": "Workflow",
    "module_id": "workflow",
    "class": "fa fa-th-large",
    "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.workflow)!=-1? true:false,
    "type_id": [2],
    "role_id":[1,2],
    "modules": [
      {
        "module_id": 1,
        "isAccess": true,
        "type_id": [2],
        "role_id":[1,2],
        "module_name": "Workflow",
        "class": "fas fa-network-wired",
        "modules": null,
        "url": "/workflow"
      },
      {
        "module_id": 99,
        "isAccess": ((this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id == 1)) || (this.userPermission && this.userPermission.chk_ids && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.createTask) != -1)) && this.userPermission.permissions != 1,
        "type_id": [2],
        "role_id":[1,2],
        "module_name": "Create A Task",
        "class": "fas fa-tasks",
        "modules": null,
        "url": true
      },
      {
        "module_id": 98,
        "isAccess": ((this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id == 1)) || (this.userPermission && this.userPermission.chk_ids && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.createNote) != -1)) && this.userPermission.permissions != 1,
        "type_id": [2],
        "role_id":[1,2],
        "module_name": "Create A Note",
        "class": "fas fa-clipboard",
        "modules": null,
        "url": true
      },
      {
        "module_id": 3,
        "isAccess": true,
        "type_id": [2],
        "role_id":[1,2],
        "module_name": "Close Multiple",
        "class":'fas fa-eraser',
        "modules": null,
        "url": "/workflow/close_mutiple"
      },
      {
        "module_id": 4,
        "isAccess": this.userPermission && this.userPermission.docusign_access=="1"?true:false,
        "type_id": [2],
        "role_id":[1,2],
        "module_name": "Docusign",
        "class":'fas fa-signature',
        "modules": null,
        "url": "/workflow/docusign"
      },
      {
        "module_id": 5,
        "isAccess": this.userPermission && this.userPermission.note_task_deletion_access=="1" && this.userPermission.note_task_user_del_access=="1" && (this.userPermission.role_id == 1 || this.userPermission.role_id == 2)?true:false,
        "type_id": [2],
        "role_id":[1,2],
        "module_name": "Delete Note/Task",
        "class":'fas fa-trash',
        "modules": null,
        "url": "/workflow/delete-note-task"
      },
      // {
      //   "module_id": 5,
      //   "isAccess": true,
      //   "type_id": [2],
      //   "role_id":[1,2],
      //   "module_name": "Task Manager",
      //   "modules": null,
      //   "url": ""
      // },
      {
        "module_id": 41,
        "isAccess": true,
        "type_id": [2],
        "role_id":[1,2],
        "module_name": "Checklist Manager",
        "class":'fas fa-check-double',
        "modules": null,
        "url": "/workflow/task_manager"
      },
      {
        "module_id": 42,
        "isAccess":this.userPermission && this.userPermission.reassign_multiple_tasks=="1" && this.userPermission.reassign_multiple_user_access=="1" && (this.userPermission.role_id == 1 || this.userPermission.role_id == 2)?true:false,
        "type_id": [2],
        "role_id":[1,2],
        "module_name": "Re-assign Multiple Tasks",
        "class":'fas fa-redo',
        "modules": null,
        "url": "/workflow/Re-assign_Multiple_Tasks"
      },
    ]
  },
  {
    "module_name": "Provider Passport",
    "module_id": "provider",
    "class": "fa fa-chart-pie",
    "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id ==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.providerPassport)!=-1? true:false,
    "type_id": [2],
    "role_id":[1,2],
    "modules": [
      {
        "module_id": 6,
        "module_name": "Show All Providers",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.showAllProvider)!=-1? true:false,
        "class":'fas fa-users',
        "type_id": [2],
        "role_id":[1,2],
        "url": "/provider/list/1"
      },
      {
        "module_id": 7,
        "module_name": "Provider portal List",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.providerPortalList)!=-1? true:false,
        "class":'fas fa-user-check',
        "type_id": [2],
        "role_id":[1,2],
        "url": "/provider/list/5"
      },
      {
        "module_id": 8,
        "module_name": "Provider Not Activated",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.portalNotActivted)!=-1? true:false,
        "class":'fas fa-user-minus',
        "type_id": [2],
        "role_id":[1,2],
        "url": "/provider/notActivated/6"
      },
      {
        "module_id": 9,
        "module_name": "Inactive Provider List",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true: this.userPermission.role_id == 1 ?true:false,
        "class":'fas fa-user-slash',
        "type_id": [2],
        "role_id":[1,2],
        "url": "/provider/notActivated/2"
      },
      // {
      //   "module_id": 10,
      //   "module_name": "DOP",
      //   "modules": null,
      //   "isAccess":  this.userPermission && this.userPermission.dop_access=="1"?true:false,
      //   "type_id": [2],
      //   "role_id":[1,2],
      //   "url": "/dop"
      // },
      {
        "module_id": 11,
        "module_name": "CMS Import",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true: this.userPermission.role_id == 1 ?true:false,
        "class":'fas fa-cloud-download-alt',
        "type_id": [2],
        "role_id":[1,2],
        "url": "/provider/cms_npi_search"
      },
      {
        "module_id": 12,
        "module_name": "CAQH Sync",
        "modules": null,
        "isAccess": this.userPermission && (this.userPermission.caqh_sync_access == "1" && this.userPermission.role_id != 5 && this.userPermission.role_id != 6) ?true:false,
        "class":'fas fa-sync',
        "type_id": [2],
        "role_id":[1,2],
        "url": "/caqh"
      },
      {
        "module_id": 13,
        "module_name": "License Verification Scheduler",
        "modules": null,
        "isAccess": this.userPermission && (this.userPermission.role_id != 5 && this.userPermission.role_id != 6) ? true : false,
        "class":'fas fa-calendar-alt',
        "type_id": [2],
        "role_id":[1,2],
        "url": "/provider/webcrawlers"
      },
      {
        "module_id": 14,
        "module_name": "CAQH Import",
        "modules": null,
        "isAccess": this.userPermission && (this.userPermission.caqh_upload_access == "1" && (this.userPermission.role_id == 1 || this.userPermission.role_id == 2)) ?true:false,
        "class":'far fa-caret-square-down',
        "type_id": [2],
        "role_id":[1,2],
        "url": "/provider/caqh_import"
      }

    ]
  },
  {
    "module_name": "Manage TIN/Locations",
    "module_id": "manageTIN/Locations",
    "class": "fa fa-map-marker-alt",
    "modules": null,
    "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.manageTInLocation)!=-1? true:false,
    "type_id": [2],
    "role_id":[1,2],
    "url": "/tin"
  },
  {
    "module_name": "Expirations",
    "module_id": "expirations",
    "class": "fa fa-heart ",
    "modules": null,
    "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.expiration)!=-1? true:false,
    "type_id": [2],
    "role_id":[1,2],
    "url": "/expirations"
  },
  {
    "module_name": "Reports",
    "module_id": "reports",
    "class": "fa fa-calendar",
    "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.reportsMod)!=-1? true:false,
    "type_id": [2],
    "role_id":[1,2],
    "modules": [
      // {
      //   "module_id": 14,
      //   "module_name": "CMO Reports",
      //   "modules": null,
      //   "isAccess": true,
      //   "type_id": [2],
      //   "role_id":[1,2],
      //   "url": "/reports/cmo-report"
      // },
      {
        "module_id": 15,
        "module_name": "FTCA Reports",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.ftcaReport)!=-1? true:false,
        "class": "fas fa-file-contract",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/reports/ftca-report"
      },
      {
        "module_id": 16,
        "module_name": "Generated Form",
        "modules": null,
        "isAccess": true,
        "class": "fas fa-file-download",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/reports/generate-form"
      },
      {
        "module_id": 17,
        "module_name": "Medical Staff Verification",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true: this.userPermission.role_id == 1 ?true:false,
        "class": "fas fa-hospital-user",
        "role_id":[1,2],
        "type_id": [2],
        "url": "/reports/medical-staff"
      },
      {
        "module_id": 18,
        "module_name": "Portal Report",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.portalReports)!=-1? true:false,
        "class": "fas fa-file-invoice",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/reports/portal-report"
      },
      {
        "module_id": 19,
        "module_name": "Provider Roster Report",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.providerRoasterReport)!=-1? true:false,
        "class": "fas fa-file-download",
        "type_id": [2],
        "role_id":[1,2],
        "url": true,
      },
      {
        "module_id": 20,
        "module_name": "Location Roster Report",
        "modules": null,
        "isAccess": true,
        "class": "fas fa-file-download",
        "type_id": [2],
        "role_id":[1,2],
        "url": true,
      },
      {
        "module_id": 21,
        "module_name": "TIN Roster Report",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.tinReports)!=-1? true:false,
        "class": "fas fa-file-download",
        "type_id": [2],
        "role_id":[1,2],
        "url": true,
      },
      {
        "module_id": 22,
        "module_name": "Canned Reports",
        "modules": null,
        "isAccess": true,
        "class": "fas fa-file-excel",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/reports/canned-reports"
      },
      {
        "module_id": 23,
        "module_name": "Canned Report Scheduler",
        "modules": null,
        "isAccess": this.userPermission && this.userPermission.canned_report_scheduler=="1" && this.userPermission.permissions!=1?true:false,
        "class": "fas fa-calendar-alt",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/reports/canned-report-scheduler"
      },
      {
        "module_id": 24,
        "module_name": "Custom Report",
        "modules": null,
        "isAccess": this.userPermission && this.userPermission.permissions!=1,
        "class": "fas fa-file-download",
        "role_id":[1,2],
        "type_id": [2],
        "url": "/reports/custom-report"
      }

    ]
  },
  {
    "module_name": "Settings",
    "module_id": "settings",
    "class": "fa fa-check-square",
    "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.settingMod)!=-1? true:false,
    "type_id": [2],
    "role_id":[1,2],
    "modules": [
       {
         "module_id": 23,
         "module_name": "History",
         "modules": null,
         "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.history)!=-1? true:false,
         "class": "fas fa-history",
         "type_id": [2],
         "role_id":[1,2],
         "url": "/settings/history"
       },
      {
        "module_id": 24,
        "module_name": "User Management",
        "url": "/settings/user",
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.userManagement)!=-1? true:false,
        "class": "fas fa-users-cog",  
        "type_id": [2],
        "role_id":[1,2],
        "modules": [{
          "module_id": 25,
          "module_name": "Add User",
          "modules": null,
          "isAccess": true,
          "type_id": [2],
          "url": "/settings"
        }
        ],
      },
      // {
      //   "module_id": 100,
      //   "module_name": "Add A User",
      //   "modules": null,
      //   "isAccess": ((this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id== 1)) || (this.userPermission && this.userPermission.chk_ids && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.addUser) !=-1)) && this.userPermission.permissions != 1,
      //   "class": "fas fa-user-plus",
      //   "type_id": [2],
      //   "role_id":[1,2],
      //   "url": "/settings/addUser/create"
      // },
      {
        "module_id": 26,
        "module_name": "Provider Portal User",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.providerPortalUser)!=-1? true:false,
        "class": "fas fa-user-check",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/settings/portal-user"
      },
      {
        "module_id": 27,
        "module_name": "Provider Uniforms Fields",
        "modules": null,
        "isAccess":  (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.uniformFields)!=-1? true:false,
        "class": "fas fa-th-list",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/settings/provider-uniform-fields"
      },
      {
        "module_id": 28,
        "module_name": "Locations Uniforms Fields",
        "modules": null,
        "isAccess":  (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.uniformFields)!=-1? true:false,
        "class": "fas fa-list",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/settings/location-uniform-fields"
      },
      {
        "module_id": 29,
        "module_name": "Admin View",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true: this.userPermission.role_id == 1 ?true:false,
        "class": "fas fa-chart-area",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/settings/manager-view"
      },
      {
        "module_id": 30,
        "module_name": "Medical Staff Verification",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true: this.userPermission.role_id == 1 ?true:false,
        "class": "fas fa-hospital-user",
        "role_id":[1,2],
        "type_id": [2],
        "url": "/settings/medicalStaffVerification"
      },
      {
        "module_id": 31,
        "module_name": "Payer Setting",
        "class": "fas fa-user-cog",
        "modules": null,
        "isAccess": this.userPermission && this.userPermission.is_payer_show == "1" ? true : false,
        "role_id":[1,2],
        "type_id": [2],
        "url": "/settings/payerSettings"
      },

      {
        "module_id": 32,
        "module_name": "API Access",
        "modules": null,
        "isAccess": this.userPermission && this.userPermission.api_access=="1"?true:false,
        "class": "fas fa-project-diagram",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/apiaccess"
      },
      {
        "module_id": 37,
        "module_name": "Onboarding Forms",
        "modules": null,
        "isAccess": (this.userPermission && this.userPermission.onboarding_forms_access == "1" && (this.userPermission.isShowAll || this.userPermission.role_id == 1 || this.userPermission.role_id == 2)),
        "class": "fas fa-user-plus",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/settings/onboarding"
      },
      {
        "module_id": 38,
        "module_name": " Predefined Selections",
        "modules": null,
        "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true: this.userPermission.role_id == 1 ?true:false,
        "class": "fas fa-check-square",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/settings/predefinedSelections"
      },

    ]
  },
  {
    "module_name": "Support",
    "module_id": "support",
    "class": "fa fa-book ",
    "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? true : this.userPermission && this.userPermission.chk_ids&& this.userPermission && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.support)!=-1? true:false,
    "type_id": [2],
    "role_id":[1,2],
    "modules": [
      {
        "module_id": 39,
        "module_name": "Contact Support",
        "modules": null,
        "isAccess": true,
        "class": "fas fa-headset",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/support/view-request"
      },
      {
        "module_id": 40,
        "module_name": "Trainer's Corner",
        "modules": null,
        "isAccess": true,
        "class": "fas fa-chalkboard-teacher",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/support/trainer-corner"
      },
      {
        "module_id": 41,
        "module_name": "No Surprises Act",
        "modules": null,
        "isAccess": true,
        "class": "fas fa-chalkboard-teacher",
        "type_id": [2],
        "role_id":[1,2],
        "url": "/support/no-surprises-act"
      }
    ]
  },
  /*provider Login*/
  {
    "module_name": "Provider",
    "module_id": "provider",
    "class": "fa fa-chart-pie",
    "modules": null,
    "isAccess": true,
    "type_id": [3, 4, 5],
    "role_id":[3,4,5,6],
    "url": "/provider-portal"
  },
  {
    "module_name": "Reports",
    "module_id": "reports",
    "class": "fa fa-calendar",
    "isAccess": true,
    "type_id": [3, 4, 5],
    "role_id":[3,4,5,6],
    "modules": [
      {
        "module_id": 14,
        "module_name": "Pending Form",
        "modules": null,
        "isAccess": true,
        "class":'fas fa-exclamation-circle',
        "type_id": [3, 4, 5],
        "role_id":[3,4,5,6],
        "url": "/pending-form"
      }  
      // },
      // {
      //   "module_id": 14,
      //   "module_name": "View Generated Forms",
      //   "modules": null,
      //   "isAccess": true,
      //   "class":'fas fa-exclamation-circle',
      //   "type_id": [3, 4, 5],
      //   "role_id":[3,4,5,6],
      //   "url": "/view-generated-forms"
      // }
    ]
  },
  {
    "isAccess": (this.userPermission && (this.userPermission.isShowAll || this.userPermission.role_id==1)) ? false : this.userPermission && this.userPermission.chk_ids && this.userPermission && this.userPermission.chk_ids.split(",").length ==1 && this.userPermission.chk_ids.split(",").indexOf(UserFieldConstant.cmd) != -1 ? true : false,
    "type_id": [2],
    "role_id": [1, 2],
    "module_name": "Workflow",
    "modules": null,
    "url": "/workflow",
    "module_id": "workflow",
    "class": "fa fa-th-large",
  },
]
  }

}
