<ngx-spinner bdColor = "rgba(51, 51, 51, 0.8)" size = "large" color = "#fff" [fullScreen] = "true"
type = "ball-clip-rotate-pulse"></ngx-spinner>
<div class="modal-header">
 <h4 class="modal-title">Create Task</h4>
 <button type="button" class="close" aria-label="Close" (click)="activeModal.close(modalResponse)">
   <span aria-hidden="true">&times;</span>
 </button>
</div>
<div class="modal-body">
 <form [formGroup]="TaskForm">
   <div class="row">
     <div class="col-md-6">
       <label for="subject" class="ml-1">Subject <span class="mandatory">*</span></label>
       <div class="form-group row">
         <input type="text" class="form-control ml-3 col-md-10" formControlName="subject" id="subject" name="subject"
           placeholder="Enter Subject" />
         <button (click)="showSubject=!showSubject" class="btn col-md-1 ml-2 pr-2   customWhite"><i
             [ngClass]="showSubject?'fa-chevron-up':'fa-chevron-down'" class="fas fa-chevron-down"></i></button>
         <small class="text-danger ml-3"
           *ngIf="TaskForm.controls['subject'].hasError('required') && TaskForm.controls['subject'].touched">
           Subject is required. </small>
       </div>
       <div *ngIf="showSubject" class="form-group">
         <ng-select (change)="handleSubject($event)" [items]="subjectDropdownData" bindLabel="name" id="subject"
           placeholder="Select Subject" bindValue="id" formControlName="selectSub" [clearable]="false">
         </ng-select>
         <!-- [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" inside ng-select for red border -->
         <!-- <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
           <div *ngIf="f.subject.errors.required">Subject is required</div>
         </div> -->
       </div>

       <div class="form-group">
         <label for="description"> Description <span class="mandatory">*</span></label>
         <textarea id="description" class="form-control" rows="4" cols="50" formControlName="description">
             </textarea>
         <small class="text-danger"
           *ngIf="TaskForm.controls['description'].hasError('required') && TaskForm.controls['description'].touched">
           Description is required. </small>
       </div>
       <div class="form-group">
         <label for="payer_id">Payer <span class="mandatory">*</span></label>
         <ng-select [virtualScroll]="true" class="auto-grow-location-2x" [items]="payerDropdownData"  bindLabel="name" [multiple]="true" 
           placeholder="Select Payer" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false" (change)="handlePayer()"
           bindValue="id" formControlName="payer_id">
           <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
             <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
             {{item.name}}
           </ng-template>
         </ng-select>
         <small class="text-danger"
           *ngIf="TaskForm.controls['payer_id'].hasError('required') && TaskForm.controls['payer_id'].touched">
           Payer is required. </small>
       </div>
       <div class="form-group">
         <label>TIN <span class="mandatory">*</span></label>
         <ng-select (change)="handleTin($event)" class="auto-grow-location-2x" [items]="tinDropdownData" bindLabel="name" name="tin"
           placeholder="Select TIN" bindValue="id" formControlName="tin_id">
         </ng-select>
         <small class="text-danger"
           *ngIf="TaskForm.controls['tin_id'].hasError('required') && TaskForm.controls['tin_id'].touched">
           TIN is required. </small>
       </div>
       <div class="form-group">
         <label>TIN/Location/Provider <span class="mandatory">*</span></label>
         <ng-select  (change)="handleTinLoc($event)" [items]="CategoryDropdownData"
           bindLabel="name" name="location-provider" placeholder="Select category" bindValue="id"
           formControlName="category_id" [clearable]="false" [readonly]="!showCat || catValidator">
         </ng-select>
         <small class="text-danger"
           *ngIf="TaskForm.controls['category_id'].hasError('required') && TaskForm.controls['category_id'].touched">
           TIN/Location/Provider is required. </small>
       </div>
       <div *ngIf="selectLabel!=undefined && selectLabel.length>0" class="form-group">
         <label>{{selectLabel}} <span class="mandatory">*</span></label>
         <!--  <small *ngIf="TaskForm.value.payer_id.length !=1 && TaskForm.value.payer_id.length !=0 && !catValidator" class="multipleSelect ml-2">( you can select only one category ) </small> -->
        <ng-select [virtualScroll]="true" class="auto-grow-location-2x" (change)="handleAffliations($event,TaskForm.value.category_id)" [items]="drop_category"  bindLabel="name"
           [selectableGroup]="true" [selectableGroupAsModel]="false" [multiple]="true " [closeOnSelect]="false" bindValue="id"
           formControlName="category_item_id" [readonly]="TaskForm.value.category_id ==1 ? true : catValidator">
           <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
             <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
             {{item.name}}
           </ng-template>
         </ng-select>
         <small class="text-danger"
           *ngIf="TaskForm.controls['category_item_id'].hasError('required') && TaskForm.controls['category_item_id'].touched">
           {{selectLabel}} is required. </small>
       </div>
       <div class="form-group" *ngIf="handleproviderId && selectLabel=='Provider'">
         <label>Hospital Affliations</label>
         <ng-select formControlName="affliations"  (change)="ValuesChange($event)"  [items]="AffliationsDropdownData" placeholder="Select Affliations"  bindLabel="name" bindValue="id" name="Affliations" [clearable]="true" 
         [multiple]="true" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"  >
         <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
           <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
           {{item.name}}
         </ng-template>
       </ng-select>
       
       
       </div>
       <div class="form-group">
         <label for="priority">Priority <span class="mandatory">*</span></label>
         <ng-select [items]="priorityDropdownData" bindLabel="name" name="Priority" placeholder="Select Priority"
           bindValue="id" formControlName="priority" [clearable]="false">
         </ng-select>
         <small class="text-danger"
           *ngIf="TaskForm.controls['priority'].hasError('required') && TaskForm.controls['priority'].touched">
           Priority is required. </small>
       </div>
       <div class="form-group">
         <label for="files">Attachment </label> <span *ngIf="(TaskForm.value.payer_id.length != 1 && TaskForm.value.payer_id.length != 0 ) || (TaskForm.value.category_item_id.length != 1 && TaskForm.value.category_item_id.length != 0)" class="multipleSelect ml-2">( File upload is not allowed for multiple Payer/Provider/Location selection) </span> <br>
         <label class="dragLabel">Drag and Drop Files Below</label>
         <input #FileToUpload  [disabled]="disableUploadFile" class="m-1 dragDrop" type="file" name="files" id="files" multiple (change)="onFileSelected($event)"
         accept=".doc,.docx,.csv,.prn,.xls,.xlsx,.xlt,.xml,.jpeg,.pdf,.doc,.dot,.ppt,.pptx"/>
       </div>
       <div class="form-group">
         <label for="location_code">Location Code </label>
         <input id="location_code" type="text" name="locationCode" class="form-control" placeholder="Enter Location Code"
           formControlName="location_code">
       </div>
       <div class="form-group">
         <label for="payer_code">Payer Code </label>
         <input id="payer_code" type="text" name="payerCode" class="form-control" placeholder="Enter Payer Code"
           formControlName="payer_code">
       </div>
     </div>

     <div class="col-md-6">
       <div class="form-group">
         <div class="row">
           <div class="col-md-7">
             <label for="custName">Status <span class="mandatory">*</span></label>
           </div>
           <!-- <div class="col-md-5">
             <div class="form-check  pull-right ">
               <input class="form-check-input" type="checkbox" formControlName="is_applicable" id="inlineRadio1"
                 value="1">
               <label class="form-check-label" for="inlineRadio1">isApplicable</label>
             </div>
           </div> -->
         </div>
         <ng-select [items]="statusDropdownData" bindLabel="name" placeholder="Select Status" bindValue="id"
           formControlName="status_id" [clearable]='false'>
         </ng-select>
         <small class="text-danger"
           *ngIf="TaskForm.controls['status_id'].hasError('required') && TaskForm.controls['status_id'].touched">
           Status is required. </small>
       </div>
       <div class="form-group">
         <label>Sub Status </label>
         <ng-select [items]="subStatusDropdownData" bindLabel="name" placeholder="Select Sub Status" bindValue="id"
           formControlName="sub_status_id">
         </ng-select>
       </div>
       <div class="form-group">
         <label>Assign To <span class="mandatory">*</span></label> <small *ngIf="(TaskForm.value.payer_id.length != 1 && TaskForm.value.payer_id.length != 0 ) || (TaskForm.value.category_item_id.length != 1 && TaskForm.value.category_item_id.length != 0)" class="multipleSelect ml-2">( you can select only one user assign ) </small>
         <ng-select [virtualScroll]="true" [items]="assignToDropdown" [multiple]="multipleSelection" bindLabel="name"
           placeholder="Select Assign To" [selectableGroup]="true" [selectableGroupAsModel]="false" [closeOnSelect]="false"
           bindValue="id" formControlName="user_assign_to">
           <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
             <input id="item-{{index}}" type="checkbox" [checked]="item$.selected" />
             {{item.name}}
           </ng-template>
         </ng-select>
         <small class="text-danger"
           *ngIf="TaskForm.controls['user_assign_to'].hasError('required') && TaskForm.controls['user_assign_to'].touched">
           Assign To is required. </small>
       </div>
       <div class="form-group">
         <label for="tracking_email">Mail Tracking Number </label>
         <input id="tracking_email" type="text" name="trackingNumber" class="form-control" placeholder="Enter Tracking Number"
           formControlName="tracking_email">
       </div>
       <div class="form-group">
         <label for="tracking_no">Payor/Medicare Tracking Number </label>
         <input id="tracking_no" type="text" name="medicareTrackingNumber" class="form-control" placeholder="Enter Tracking Number"
           formControlName="tracking_no">
       </div>
       <div class="form-group">
         <label for="contact_name">Contact Name </label>
         <input id="contact_name" type="text" name="taskID" class="form-control" placeholder="Enter Name" formControlName="contact_name">
       </div>

       <div class="form-group">
         <label for="email_id">Email</label>
         <input id="email_id" type="text" name="emailID" class="form-control" placeholder="Enter Email" formControlName="email_id">
       </div>

       <div class="form-group">
         <label for="phone_number">Phone Number </label>
         <input id="phone_number" type="text" name="phoneNumber" class="form-control" placeholder="Enter Phone Number"
           formControlName="phone_number" maxlength='10'>
       </div>
       <div class="form-group">
         <label for="phone_extension">Phone Number Extension </label>
         <input id="phone_extension" type="text" name="phoneNumber" class="form-control" placeholder="Enter Phone Number Extension"
           formControlName="phone_extension" maxlength='10'>
       </div>
       <div class="form-group">
         <label for="fax">Fax </label>
         <input id="fax" type="text" name="taskID" class="form-control" placeholder="Enter Fax" formControlName="fax"
           maxlength='10'>
       </div>
       <div class="form-group">
         <label>Due Date Follow up Date <span class="mandatory">*</span></label>
         <div class="input-group">
           <input class="form-control" formControlName="specific_date" name="dp" placeholder="mm-dd-yyyy" ngbDatepicker appMaskInput [appMaskValue]="this.TaskForm.value.specific_date"
             [placement]="'top'" #d="ngbDatepicker" placement="bottom-left" container="body" autoClose="outside"
             [minDate]="date">
           <div class="input-group-append">
             <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"><i
                 class="fas fa-calendar-alt"></i></button>
           </div>
         </div>
         <small class="text-danger" *ngIf="f['specific_date'].hasError('required') && TaskForm.controls['specific_date'].touched">
           Due Date Follow up Date is required. </small>
       </div>
       <div class="form-group">
         <div class="form-check">
           <input class="form-check-input" type="checkbox" id="is_mail_request" formControlName="is_mail_request" value="1">
           <label class="form-check-label" for="is_mail_request">
             Email Reminder
           </label>
         </div>
       </div>
       <div class="form-group" *ngIf="selectLabel=='Provider'">
         <div class="form-check">
           <input class="form-check-input" type="checkbox" id="is_apply_payer_to_provider" formControlName="is_apply_payer_to_provider" value="1">
           <label class="form-check-label" for="is_apply_payer_to_provider">
             Apply Payer To Provider
           </label>
         </div>
       </div>
       <div class="form-group">
         <div class="form-check">
           <input class="form-check-input" type="checkbox" id="is_notifying_user" formControlName="is_notifying_user" value="1">
           <label class="form-check-label" for="is_notifying_user">
             Notify Users of New Task
           </label>
         </div>
       </div>
     </div>
   </div>
 </form>

</div>
<div class="modal-footer">
 <button type="button" class="btn customOrange float-right" (click)="onClear()">Clear</button>
 <button *ngIf="!readOnlyUser" type="button" class="btn customOrange float-right" [disabled]="TaskForm.invalid"
   (click)="save()">Create</button>
</div>
