import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, NgbModalOptions, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToasterNotiService } from '../../services/notifications/toaster-noti.service';
import { OnboardingformsServiceService } from '../../services/onboardingforms-service/onboardingforms-service.service';
import { StorageService } from '../../services/storage/storage.service';
import { EsignComponent } from '../esign/esign.component';
import {
  CustomAdapter,
  CustomDateParserFormatter
} from '../../DateFormatter/customDate';
import { ConstantPool } from '@angular/compiler';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgIf } from '@angular/common';
@Component({
    selector: 'flagship',
    templateUrl: './flagship.component.html',
    styleUrls: ['./flagship.component.scss'],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, NgIf, NgSelectModule, NgbDatepickerModule]
})

export class flagshipComponent implements OnInit {
  @Input() formData;
  @Input() fieldsData;
  @Input() formId;
  @Output() onSubmit = new EventEmitter();
  @Output() onClose = new EventEmitter();
  constructor(
    private fb: FormBuilder,
    private onboardService: OnboardingformsServiceService,
    private spinner: NgxSpinnerService,
    private notiService: ToasterNotiService,
    private modalService: NgbModal,
    private _sanitizer: DomSanitizer,
    private storageService: StorageService
  ){}  
  public userId: any;
  public customerId: any;
  public FlagShipForms:FormGroup;
  credentials: Array<object> = [];
  states: Array<object> = [];
  altLicenseState: Array<object> = [];
  CdsStates:Array<object>=[];
  AltCdsStates:Array<object>=[];      
  AddCdsStates:Array<object>=[];  
  Add2CdsStates:Array<object>=[];  
  Dea2States:Array<object>=[];  
  AddDeaStates:Array<object>=[];  
  additionalLicenseStateData:Array<object>=[];  

  specialty: Array<object> = [];
  specialtySecondary: Array<object> = [];
  degree: Array<object> = [];
  boardCertification: Array<object> = [];
  preClaim: Array<object> = [];     
  aggregates: Array<object> = [];
  
  isSubmit: boolean = false;
  private phonePattern =/^[0-9]{10}$/;
  private emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  private ssnPattern=/^[0-9]{10}$/;
  finalImgPath: any = [];
  finalImg: any;
  esignOne: any;
  showOne: any = false;   
  modalOption: NgbModalOptions = {
    size: 'md',
    keyboard: false,
    backdrop: 'static'
    };


  ngOnInit(): void {
    
    this.userId = Number(this.storageService.getItem('userId'));
    this.customerId = Number(this.storageService.getItem('customerId'));
    this.buildFlagShipForm();
    this.afterSaveDataDisplay();
    this. getCredentialsData();
    this.getStatesData();
    this.getAlternateLicenseStateData();
    this.getAdditionalLicenseStateData();
    this.getCdsStatesData();
    this.getAltCdsStatesData();
    this.getAdditionalCdsStatesData();
    this.getAdditional2CdsStatesData();
    this.getDea2StatesData();
    this.getAdditionalDeaStatesData();
    this.getSpecialtyData();
    this.getSpecialtySecondaryData();
    this.getDegreeData();
    this.getBoardCertificationData();
    this.getPreClaimData();
    this.getAggregatesData();
    this.fieldsData && this.bindFormValue(this.fieldsData);
 
 
    
  }
  public  phoneFormatValidator(control: FormControl): ValidationErrors | null {
    const phoneString = control.value;
    const phoneFormat = /^[0-9]{10}$/;
  
    if (phoneString && !phoneString.match(phoneFormat)) {
      return { 'invalidPhoneFormat': true };
    }
  
    return null;
  }
  // public  dateFormatValidator(control: FormControl): ValidationErrors | null {
  //   const dateString = control.value;
  //   const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
  //   if (dateString && !dateString.match(dateFormat)) {
  //     return { 'invalidDateFormat': true };
  //   }
  
  //   return null;
  // }

  public getCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getCdsStatesData(params).subscribe(
      data => {
        // console.log(data);
        if (data['success']) {
          this.CdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }

  public getAltCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAltCdsStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.AltCdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAdditionalCdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalCdsStatesData(params).subscribe(
      data => {
        // console.log(data);
        if (data['success']) {
          this.AddCdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAdditional2CdsStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditional2CdsStatesData(params).subscribe(
      data => {
        // console.log(data);
        if (data['success']) {
          this.Add2CdsStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getDea2StatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getDea2StatesData(params).subscribe(
      data => {
        // console.log(data);
        if (data['success']) {
          this.Dea2States = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAdditionalDeaStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalDeaStatesData(params).subscribe(
      data => {
        // console.log(data);
        if (data['success']) {
          this.AddDeaStates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }




  public getCredentialsData() {
    let params: {};
    ////this.spinner.show();
    this.onboardService.getCredentialsData(params).subscribe(
      data => {
        if (data['success']) {
          this.credentials = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        ////this.spinner.hide();
      },
      error => {
        ////this.spinner.hide();
      }
    );
  }
  

  
  public getStatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getStatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.states = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSpecialtyData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtyData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialty = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getSpecialtySecondaryData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getSpecialtySecondaryData(params).subscribe(
      data => {
        if (data['success']) {
          this.specialtySecondary = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  // public bindFormValue(array) {
  //     let fieldsCtrls = {};
  //     for (let i = 0; i < array.length; i++) {
  //       fieldsCtrls[array[i].field_name] = array[i].field_value;
       
   
  //     }
      
  //     this.FlagShipForms.patchValue(fieldsCtrls);
  //   }
  public bindFormValue(array) {
    let fieldsCtrls = {};
    for (let i = 0; i < array.length; i++) {
      fieldsCtrls[array[i].field_name] = array[i].field_value;
      if (array[i].field_name == 'cmd_040') {
        if (array[i].field_value != '') {
          this.previewEsign(array[i].field_value, array[i].field_name);
        }
      }
 
    }
    
    this.FlagShipForms.patchValue(fieldsCtrls);
}
  public afterSaveDataDisplay() {
    let params = {
      type: 46,
      formId: this.formId
    };
    //this.spinner.show();
    this.onboardService.toGetSaveData(params).subscribe(
      data => {
        if (data['success']) {
          this.bindFormValue(data.data);
        } else {
          // this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        // this.notiService.showError(Error_Message, '', 3000)
      }
    );
  }
  public getDegreeData() {
    let params: {};
    this.onboardService.getDegreeData(params).subscribe(
      data => {
        if (data['success']) {
          this.degree = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
      },
      error => {
      }
    );
  }
  public getAlternateLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAlternateLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.altLicenseState = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAdditionalLicenseStateData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAdditionalLicenseStateData(params).subscribe(
      data => {
        if (data['success']) {
          this.additionalLicenseStateData = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getBoardCertificationData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getBoardCertificationData(params).subscribe(
      data => {
        if (data['success']) {
          this.boardCertification = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  } 
  public getPreClaimData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getPreClaimData(params).subscribe(
      data => {
        if (data['success']) {
          this.preClaim = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public getAggregatesData() {
    let params: {};
    //this.spinner.show();
    this.onboardService.getAggregatesData(params).subscribe(
      data => {
        if (data['success']) {
          this.aggregates = data.data.map(i => ({
            ...i,
            id: i.id.toString()
          }));
        } else {
          this.notiService.showError(data.error, '', 3000);
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
  }
  public setEsign(filePath, fieldId) {
    // console.log(filePath);
    if (fieldId == 'cmd_040') {
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + filePath
      );
      this.showOne = true;
    }
  }

public previewEsign(filePath, fieldId) {
    //this.spinner.show();
    let params = {
      filePath: filePath
    };
    this.onboardService.getPreviewEsign(params).subscribe(
      (data: any) => {
        if (data['success']) {
          this.setEsign(data.base64, fieldId);
        } else {
          //this.notiService.showError(data.error, '', 3000)
        }
        //this.spinner.hide();
      },
      error => {
        //this.spinner.hide();
      }
    );
}
public esignOpenOne() {
    const modalRef = this.modalService.open(EsignComponent, this.modalOption);
    modalRef.result.then(result => { });
    modalRef.componentInstance.base64Img.subscribe(data => {
      modalRef.close();
      this.finalImgPath = data.finalFilePath;
      let temp = {
        cmd_040: this.finalImgPath
      };
      this.FlagShipForms.controls['cmd_040'].setValue(this.finalImgPath);
      this.finalImg = data.base64Img;
      this.esignOne = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + this.finalImg
      );
      this.showOne = true;
    });
  }
  setErrorControl(name){
    this.FlagShipForms.controls[name].setErrors({ errors: true });
  }
  removeErrorControl(name){
    this.FlagShipForms.controls[name].setErrors(null)
  }
  
  handleNAchange(cName,eName?){
    let result:boolean=false;
    if(cName=='cmd_NA_01'){
      let x=[445,446,448,450,451,454,453]
      x.forEach(element => {
        const ctrl = this.FlagShipForms.controls[element];
        // if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
        //   this.setErrorControl(element);
        //   result = true;
        // } else if ((element === 454  ) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
        //   this.setErrorControl(element);
        //   ctrl.setErrors({ 'incorrectDateFormat': true });
        //   result = true;
        // } else if ((element === 454 ) && this.FlagShipForms.controls[cName].value) {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // } else {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // }
        if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
          this.setErrorControl(element);
          result = true;
        } else if ((((element ===453) && !this.phonePattern.test(ctrl.value)  ) )&& (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
          this.setErrorControl(element);
          ctrl.setErrors({ 'incorrectDateFormat': true });
          result = true;
        } else if (( (element ===453)) && this.FlagShipForms.controls[cName].value) {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        } else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }  
    if(cName=='cmd_NA_02'){
      let x=[517,518,520,522,523,2539,527,524,525]
      x.forEach(element => {
        const ctrl = this.FlagShipForms.controls[element];
        if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
          this.setErrorControl(element);
          result = true;
        }
        //  else if ((element === 524 || element ===525 ) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
        //   this.setErrorControl(element);
        //   ctrl.setErrors({ 'incorrectDateFormat': true });
        //   result = true;
        // } else if ((element === 524 || element ===525) && this.FlagShipForms.controls[cName].value) {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // } 
        else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if(cName=='cmd_NA_03'){
      let x=[529,540,531,533,535,536,530,537,538]
      x.forEach(element => {
        const ctrl = this.FlagShipForms.controls[element];
        if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
          this.setErrorControl(element);
          result = true;
        }
        //  else if ((element === 537 || element ===538 ) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
        //   this.setErrorControl(element);
        //   ctrl.setErrors({ 'incorrectDateFormat': true });
        //   result = true;
        // } 
        // else if ((element === 537 || element ===538) && this.FlagShipForms.controls[cName].value) {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // } 
        else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if(cName=='cmd_NA_04'){
      let x=[309,312,2923,310,311]
      x.forEach(element => {
        const ctrl = this.FlagShipForms.controls[element];
        if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
          this.setErrorControl(element);
          result = true;
        } 
        // else if ((element === 310 || element ===311 ) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
        //   this.setErrorControl(element);
        //   ctrl.setErrors({ 'incorrectDateFormat': true });
        //   result = true;
        // } 
        // else if ((element === 310 || element ===311) && this.FlagShipForms.controls[cName].value) {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // }
         else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if(cName=='cmd_NA_05'){
      let x=[996,337,338,339]
      x.forEach(element => {
        const ctrl = this.FlagShipForms.controls[element];
        if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
          this.setErrorControl(element);
          result = true;
        } 
        // else if ((element === 338 || element ===339 ) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
        //   this.setErrorControl(element);
        //   ctrl.setErrors({ 'incorrectDateFormat': true });
        //   result = true;
        // } 
        // else if ((element === 338 || element ===339) && this.FlagShipForms.controls[cName].value) {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // }
         else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if(cName=='cmd_NA_06'){
      let x=[359,369,371,372,381,383,384,394,396]
      x.forEach(element => {
        // const ctrl = this.FlagShipForms.controls[element];
        // if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
        //   this.setErrorControl(element);
        //   result = true;
        // } else if ((element === 369 || element ===381 || element ===394) && !/^[0-9]{10}$/.test(ctrl.value) && (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
        //   this.setErrorControl(element);
        //   ctrl.setErrors({ 'incorrectPhoneFormat': true });
        //   result = true;
        // } else if ((element === 369 || element ===381 || element ===394) && this.FlagShipForms.controls[cName].value) {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // } else {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // }
        const ctrl = this.FlagShipForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          } else if (((element === 371|| element === 383 || element === 396  ) && !this.emailPattern.test(ctrl.value) || ((element === 369 || element ===381 || element ===394) && !/^[0-9]*$/.test(ctrl.value)  ) )&& (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
            this.setErrorControl(element);
            ctrl.setErrors({ 'incorrectDateFormat': true });
            result = true;
          } else if ((element === 371|| element === 383 || element === 396 || (element === 369 || element ===381 || element ===394)) && this.FlagShipForms.controls[cName].value) {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          } else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }
    if(cName=='cmd_NA_07'){
      let x=[554,557,560]
      x.forEach(element => {
        const ctrl = this.FlagShipForms.controls[element];
        if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
          this.setErrorControl(element);
          result = true;
        }
        //  else if ((element === 557 || element === 560 ) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) && (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
        //   this.setErrorControl(element);
        //   ctrl.setErrors({ 'incorrectDateFormat': true });
        //   result = true;
        // }
        //  else if ((element === 557 || element ===560) && this.FlagShipForms.controls[cName].value) {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // }
         else {
          this.removeErrorControl(element);
          ctrl.setErrors(null);
        }
      });
    }
    if(cName=='cmd_NA_08'){
      let x=[345,350,351,901,903,905,906,346,348,349,1240,1266]
      x.forEach(element => {
        // const ctrl = this.FlagShipForms.controls[element];
        // if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
        //   this.setErrorControl(element);
        //   result = true;
        // } else if ((element === 1266 ) && !/^[0-9]{10}$/.test(ctrl.value) && (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
        //   this.setErrorControl(element);
        //   ctrl.setErrors({ 'incorrectPhoneFormat': true });
        //   result = true;
        // } else if ((element === 1266 ) && this.FlagShipForms.controls[cName].value) {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // } else {
        //   this.removeErrorControl(element);
        //   ctrl.setErrors(null);
        // }
        const ctrl = this.FlagShipForms.controls[element];
          if ((ctrl.value === '' || ctrl.value === null) && !this.FlagShipForms.controls[cName].value) {
            this.setErrorControl(element);
            result = true;
          }
          //  else if (((element === 348 || element === 349 ) && !/^\d{2}\/\d{2}\/\d{4}$/.test(ctrl.value) || ((element ===1266) && !/^[0-9]{10}$/.test(ctrl.value)  ) )&& (!this.FlagShipForms.controls[cName].value || (this.FlagShipForms.controls[cName].value && ctrl.value !== '' && ctrl.value !== null))) {
          //   this.setErrorControl(element);
          //   ctrl.setErrors({ 'incorrectDateFormat': true });
          //   result = true;
          // } 
          // else if (((element === 348 || element === 349 )|| (element===1266)) && this.FlagShipForms.controls[cName].value) {
          //   this.removeErrorControl(element);
          //   ctrl.setErrors(null);
          // } 
          else {
            this.removeErrorControl(element);
            ctrl.setErrors(null);
          }
      });
    }

  }


saveForm(type) {
  this.isSubmit = true;
  this.handleNAchange('cmd_NA_01');
  this.handleNAchange('cmd_NA_02');
  this.handleNAchange('cmd_NA_03');
  this.handleNAchange('cmd_NA_04');
  this.handleNAchange('cmd_NA_05');
  this.handleNAchange('cmd_NA_06');
  this.handleNAchange('cmd_NA_07');
  this.handleNAchange('cmd_NA_08');
 
  console.log(this.FlagShipForms);
  if (type == 'submit') {
    if (this.FlagShipForms.invalid) {
      this.notiService.showError(
        'Please Fill the all mandatory fields to continue',
        '',
        3000
      );
      for (const key in this.FlagShipForms.controls) {
        if (this.FlagShipForms.controls.hasOwnProperty(key)) {
          const element = this.FlagShipForms.controls[key];
          if(element.status=="INVALID"){
          console.log(key + " " + element.status)
          }
        }
      }
    }
    else {
      if (this.FlagShipForms.controls['cmd_040'].value == null || this.FlagShipForms.controls['cmd_040'].value == '') {
        this.notiService.showError('Please add your Signature')
        return
      }
      let data = {
        formType: type,
        formValues: this.FlagShipForms.value
      };
      console.log("Form has been submitted successfully");
      this.onSubmit.emit(data);
      console.log(this.FlagShipForms);

    }
  } else {
    let data = {
      formType: type,
      formValues: this.FlagShipForms.value,
      formId: 46
    };
    this.onSubmit.emit(data);
    this.onClose.emit('close modal');
  }
}

  close() {
    this.onClose.emit('close modal');
  }
  buildFlagShipForm(){
    this.FlagShipForms=this.fb.group({
      8:['',[Validators.required]],                
      9:['',[Validators.required]],         
      809:[''],
      822:[null,[Validators.required]],
      31:[null, [Validators.required ,this.dateFormatValidator]],
      1680:['',[Validators.required]],
      33:[''],
      278:[''],
      32:['',[Validators.required,Validators.pattern(this.ssnPattern)]],
      1634:['',[Validators.required,Validators.pattern(this.ssnPattern)]],
      810:['',[Validators.required]],
      289:[null,[Validators.required]],
      291:['',[Validators.required]],
      16:['',[Validators.required]],
      17:[null],
       18:['',[Validators.required]],
       20:[null,[Validators.required]],
       21:['',[Validators.required]],
       759:['',[Validators.required,Validators.pattern(this.emailPattern)]],
       708:['',[Validators.required,Validators.pattern(this.phonePattern)]],
       445:['',],
       453:['',[Validators.pattern(this.phonePattern)]],
       454:[null, [this.dateFormatValidator]],
       446:[''],
       448:[''],
       450:[null],
       451:[''],
       456:[''],
       464:['',[this.phoneFormatValidator]],
       465:[null,[this.dateFormatValidator]],
       
       
       457:[''],
       458:[''],
       459:[''],
       461:[null],
       
       
       
       
       462:[''],
       284:[null],
       cmd_016:[null],
       cmd_NA_01:[null],
      
       
       447:[''],
       
       
       
       

       
       466:[null,[this.dateFormatValidator] ],
       467:[''],
       468:[''],
       469:[''],
       470:[''],
       472:[null],
       473:[''],
       476:[null,[this.dateFormatValidator] ],
       477:[null,[this.dateFormatValidator] ],
       1935:[''],
       1936:[''],
       1937:[''],
       1938:[''],
       1940:[null],
       1941:[''],
       1947:[null,[this.dateFormatValidator] ],
       1948:[null,[this.dateFormatValidator] ],
       add_1_1954:[''],
       add_1_1955:[''],
       add_1_1956:[''],
       add_1_1957:[''],
       add_1_1959:[null],
       add_1_1960:[''],
       add_1_1966:[null,[this.dateFormatValidator]],
       add_1_1967:[null,[this.dateFormatValidator]],
       add_2_1954:[''],
       add_2_1955:[''],
       add_2_1956:[''],
       add_2_1957:[''],
       add_2_1959:[null],
       add_2_1960:[''],
       add_2_1966:[null,[this.dateFormatValidator]],
       add_2_1967:[null,[this.dateFormatValidator]],

       490:['',Validators.required],
       491:['',Validators.required],
       492:[''],
       493:['',Validators.required],
       495:[null,Validators.required],
       496:['',Validators.required],
       500:[null,Validators.required],
       497:[null, [Validators.required ,this.dateFormatValidator]],
       498:[null, [Validators.required ,this.dateFormatValidator]],
       1288:[''],
       1289:[''],
       1290:[''],
       1291:[''],
       1293:[null],
       1294:[''],
       1298:[null],
       1295:[null,[this.dateFormatValidator] ],
       1296:[null,[this.dateFormatValidator] ],
       514:['',],
       515:[null,[this.dateFormatValidator] ],
       cmd_NA_02:[null],
       517:[''],
       518:[''],
       519:[''],
       520:[''],
       522:[null],
       523:[''],
       2539:[''],
       527:[null],
       524:[null, [this.dateFormatValidator]],
       525:[null, [this.dateFormatValidator]],
       cmd_NA_03:[null],
       529:[''],
       540:[''],
      531:[''],
      1498:[''],
      533:[''],
      535:[null],
      536:[''],
      530:[null],
      537:[null, [this.dateFormatValidator]],
      538:[null, [this.dateFormatValidator]],
      1170:[''], 
      1181:[''], 
      1172:[''], 
      1173:[''], 
      1174:[''], 
      1176:[null], 
      1177:[''], 
      1171:[null], 
      1178:[null,[this.dateFormatValidator] ], 
      1179:[null,[this.dateFormatValidator] ], 
      1146:[''], 
      1148:[''], 
      1149:[''], 
      1150:[''], 
      1152:[null], 
      1153:[''], 
      1382:[''], 
      1154:[''], 
      1155:[null,[this.dateFormatValidator] ], 
      1156:[null,[this.dateFormatValidator] ], 
      1157:[''], 
      1159:[''], 
      1160:[''], 
      1161:[''], 
      1163:[null], 
      1164:[''], 
      1383:[''], 
      1165:[''], 
      1166:[null,[this.dateFormatValidator] ], 
      1167:[null,[this.dateFormatValidator] ], 
      418:[''], 
      425:[''], 
      426:[null,[this.dateFormatValidator] ], 
      2007:[null,[this.dateFormatValidator] ], 
      427:[''], 
      434:[''], 
      435:[null,[this.dateFormatValidator] ], 
      2008:[null,[this.dateFormatValidator] ], 
      436:[''], 
      443:[''], 
      444:[null,[this.dateFormatValidator] ], 
      2011:[null,[this.dateFormatValidator] ], 
      299:[null,Validators.required],
      296:['',Validators.required],
      293:['',Validators.required],
      1918:['',Validators.required],
      294:[null, [Validators.required ,this.dateFormatValidator]],
      295:[null, [Validators.required ,this.dateFormatValidator]],
      907:[null],
      1274:[''],
      300:[''],
      1919:[''],
      307:[null,[this.dateFormatValidator] ],
      308:[null,[this.dateFormatValidator] ],
       //add1
      add_1_1010:[null],  
      add_1_1432:[''],
      add_1_999:[''],
      add_1_1920:[''],
      add_1_1003:[null,[this.dateFormatValidator] ],
      add_1_1004:[null,[this.dateFormatValidator] ],
      //add2
      add_2_1010:[null],  
      add_2_1432:[''],
      add_2_999:[''],
      add_2_1920:[''],
      add_2_1003:[null,[this.dateFormatValidator] ],
      add_2_1004:[null,[this.dateFormatValidator] ],

      //add3
      add_3_1010:[null],  
      add_3_1432:[''],
      add_3_999:[''],
      add_3_1920:[''],
      add_3_1003:[null,[this.dateFormatValidator] ],
      add_3_1004:[null,[this.dateFormatValidator] ],
      cmd_NA_04:[null],
      309:[''],
      312:[null],
      2923:[''],
      310:[null, [this.dateFormatValidator]],
      311:[null, [this.dateFormatValidator]],
      314:[''],
      908:[null],
      2924:[''],
      315:[null, [this.dateFormatValidator]],
      316:[null, [this.dateFormatValidator]],

      //add1

      add_1_1006:[''],
      add_1_1009:[null],
      add_1_2925:[''],
      add_1_1007:[null, [this.dateFormatValidator]],
      add_1_1008:[null, [this.dateFormatValidator]],

      //add2
      add_2_1006:[''],
      add_2_1009:[null],
      add_2_2925:[''],
      add_2_1007:[null, [this.dateFormatValidator]],
      add_2_1008:[null, [this.dateFormatValidator]],
      //add3
      add_3_1006:[''],
      add_3_1009:[null],
      add_3_2925:[''],
      add_3_1007:[null, [this.dateFormatValidator]],
      add_3_1008:[null, [this.dateFormatValidator]],
      cmd_NA_05:[null],

      996:[null],
      337:[''],
      338:[null, [this.dateFormatValidator]],
      339:[null, [this.dateFormatValidator]],
      997:[null],
      340:['',Validators.pattern(this.phonePattern)],
      341:[null, [this.dateFormatValidator]],
      342:[null, [this.dateFormatValidator]],
      2260:[null],
      2254:['',Validators.pattern(this.ssnPattern)],
      2255:[null, [this.dateFormatValidator]],
      2256:[null, [this.dateFormatValidator]],
      2261:[null],
      2257:['',Validators.pattern(this.ssnPattern)],
      2258:[null, [this.dateFormatValidator]],
      2259:[null, [this.dateFormatValidator]],
      cmd_NA_06:[null],
      359:[''],
      369:['',Validators.pattern(this.phonePattern)],
      371:['',Validators.pattern(this.emailPattern)],
      372:[''],
      381:['',[Validators.pattern(this.phonePattern)]],
      383:['',[Validators.pattern(this.emailPattern)]],
      384:['',],
      394:['',[Validators.pattern(this.phonePattern)]],
      396:['',Validators.pattern(this.emailPattern)],
      cmd_NA_07:[null],
      554:[null],
      557:[null, [this.dateFormatValidator]],
      560:[null, [this.dateFormatValidator]],
      561:[null],
      564:[null, [this.dateFormatValidator]],
      567:[null, [this.dateFormatValidator]],
      946:[null],
      953:[null, [this.dateFormatValidator]],
      954:[null, [this.dateFormatValidator]],
      cmd_017:[''],
      cmd_018:[''],
      cmd_019:[''],
      cmd_020:['',Validators.required],
      cmd_021:['',Validators.required],
      cmd_022:['',Validators.required],
      cmd_023:['',Validators.required],
      cmd_024:['',Validators.required],
      cmd_025:['',[Validators.required,this.dateFormatValidator]],
      cmd_026:['',Validators.required],
      cmd_027:['',Validators.required],
      cmd_028:['',Validators.required],
      cmd_029:['',Validators.required],
      cmd_030:['',Validators.required],
      cmd_031:['',Validators.required],
      cmd_032:['',Validators.required],
      cmd_033:['',Validators.required],
      cmd_034:['',Validators.required],
      cmd_035:['',Validators.required],
      cmd_036:['',Validators.required],
      cmd_037:['',Validators.required],
      cmd_NA_08:[null],
      345:[''],   
      350:[null],   
      351:[null],   
      901:[''],
      902:[''],
      903:[''],
      905:[null], 
      906:[''], 
      346:[''], 
      348:[null, [this.dateFormatValidator]],
      349:[null, [this.dateFormatValidator]],
      1240:[''],
      1266:['',Validators.pattern(this.phonePattern)],
      1509:[''],
      1525:[null],
      1526:[null],
      1511:[''],
      1512:[''],
      1513:[''],
      1515:[null],
      1516:[''],
      1520:[''],
      1523:[null, [this.dateFormatValidator] ],
      1524:[null,[this.dateFormatValidator]],
      1510:[''],
      1518:['',[this.phoneFormatValidator]],
      cmd_038:['',Validators.required],
      cmd_039:['',Validators.required],
      cmd_040:['',Validators.required],
      cmd_041:[null, [Validators.required ,this.dateFormatValidator]],
      cmd_042:[''],
     

      


      

      
      


      
       

      
      

       
 
     }) 
  }

  clearInputIfInvalid(controlName: string,handleYesNoValue?:string) {
    const control = this.FlagShipForms.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');
      control.setErrors(null);
    }
    if(handleYesNoValue ){
      this.handleNAchange(handleYesNoValue);
    }
    
    
  }
  clearInputIfInvalidMandi(controlName: string) {
    const control = this.FlagShipForms.get(controlName);
    const dateString = control.value;
  
    // Use the dateFormatValidator to check if the date is valid
    const validationErrors = this.dateFormatValidator(new FormControl(dateString));
  
    if (validationErrors) {
      // If any of the validation conditions are met, clear the input field and set errors to null
      control.setValue('');

    }}

  dateFormatValidator(control: FormControl): ValidationErrors | null {
    const dateString = control.value;
    const dateFormat = /^\d{2}\/\d{2}\/\d{4}$/;
  
    if (dateString && !dateString.match(dateFormat)) {
      return { 'invalidDateFormat': true };
    }
  


    if (dateString) {
      const dateParts = dateString.split('/');
      const month = parseInt(dateParts[0]);
      const day = parseInt(dateParts[1]);
      const year = parseInt(dateParts[2]);
  
      // Check if any of the date components are zero or month is not between 1 and 12
      
  
      const ListofDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      
      
      if (month < 1 || month > 12 || day === 0 || year === 0) {
        return { 'invalidMonth': true };
      }
      if (month === 1 || month > 2) {
        if (day > ListofDays[month - 1]) {
          return { 'invalidDateRange': true };
        }
      } else if (month === 2) {
        const leapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
        if (!leapYear && day >= 29) {
          return { 'invalidDateRange': true };
        }
         else if (leapYear && day > 29) {
          return { 'invalidDateRange': true };
        }
      }
      if (year < 1900 || year > 2099) {
        return { 'invalidDateyear': true };
      }
      
     
    }
  
    return null;
  }

}